import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";

import "normalize.css/normalize.css";
// css 动画效果
import "animate.css";
// 全局样式引入
import "@/styles/index.scss";

// ui 组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 路由
import router from "./router";

import App from "./App.vue";

Vue.config.productionTip = false;

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

Vue.use(ElementUI);

// 注册动画指令 滚动到位置加载动画
Vue.directive("animate", {
  inserted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect();
      const h =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (top < h) {
        if (el.className.indexOf(binding.value) === -1) {
          // 下面单引号中间是一个空格
          el.className = binding.value + " " + el.className;
        }
        if (binding.addClass) {
          window.removeEventListener("scroll", binding.addClass);
        }
      }
    };
    window?.addEventListener("scroll", binding.addClass, true);
    binding.addClass();
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener("scroll", binding.addClass);
    }
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
