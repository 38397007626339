<template>
  <ul class="icon-box">
    <li
      class="icon-item"
      @mouseenter="setQrcodeActive(true)"
      @mouseleave="setQrcodeActive(false)"
    >
      <img v-if="!qrcodeActive" src="@/assets/image/side-icon/icon1.png" />
      <img v-else src="@/assets/image/side-icon/active-icon1.png" />

      <div v-if="qrcodeActive" class="box1">
        <img src="@/assets/image/side-icon/index.jpg" alt="" />
      </div>
    </li>
    <li class="icon-item" @click="setActive(1)">
      <img v-if="active !== 1" src="@/assets/image/side-icon/icon2.png" />
      <img src="@/assets/image/side-icon/active-icon2.png" />
      <div v-if="active === 1" class="box2">
        <router-link to="/furnish/color-inspiration/index">
          空间<br />试色
        </router-link>
        <router-link to="/furnish/store"> 门店<br />查询 </router-link>
        <router-link to="/furnish/paint"> 漆量<br />计算 </router-link>
        <router-link to="/furnish/serve/authenticity">
          真伪<br />查询
        </router-link>
      </div>
    </li>
    <li class="icon-item" @click="setActive(2)">
      <img v-if="active !== 2" src="@/assets/image/side-icon/icon3.png" />
      <img src="@/assets/image/side-icon/active-icon3.png" />

      <div v-if="active === 2" class="box3">
        <a
          href="https://carpoly.s4.udesk.cn/im_client/?web_plugin_id=17247"
          target="__blank"
        >
          在线<br />客服
        </a>
        <router-link to="/about/zsjm"> 加盟<br />合作 </router-link>
        <router-link to="/about/contact"> 联系<br />我们 </router-link>
        <router-link to="/about/introduce"> 企业<br />介绍 </router-link>
      </div>
    </li>

    <li class="back-top" @click.prevent="backTop">
      <img src="@/assets/image/side-icon/icon4.png" />
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      qrcodeActive: false,
      active: 0,
    };
  },
  methods: {
    setQrcodeActive(value) {
      this.qrcodeActive = value;
    },

    setActive(active) {
      this.active = this.active === active ? 0 : active;
    },

    backTop() {
      this.active = 0;
      let top =
        this.$route.path === "/furnish/art-paint"
          ? document.querySelector(".art-page").scrollTop
          : document.querySelector(".app-container").scrollTop;
      const timer = setInterval(() => {
        top -= Math.abs(top * 0.3);
        if (top <= 1) {
          top = 0;
          clearInterval(timer);
        }

        this.$route.path === "/furnish/art-paint"
          ? (document.querySelector(".art-page").scrollTop = top)
          : (document.querySelector(".app-container").scrollTop = top);
      }, 20);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-box {
  position: fixed;
  top: 70%;
  right: 33px;
  transform: translateY(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  & > .icon-item {
    position: relative;
    width: 56px;
    height: 56px;
    & > img {
      position: absolute;
      top: 0;
      left: 0;
    }

    & > img:nth-child(1) {
      z-index: 10000;
    }
    & > img:nth-child(2) {
      z-index: 9999;
    }

    .box1 {
      position: absolute;
      top: 0px;
      left: -124px;
      width: 120px;
      height: 120px;
      z-index: 10000;
      & > img {
        width: 100%;
        height: 100%;
      }
    }

    .box2,
    .box3 {
      position: absolute;
      left: -68px;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #eee;
      & > a {
        width: 55px;
        height: 55px;
        color: #b1b1b1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
      & > a:hover {
        color: red;
      }
    }

    .box2 {
      top: -56px;
    }
    .box3 {
      top: -112px;
    }
  }

  & > .icon-item:hover {
    & > img:nth-child(1) {
      z-index: 9999;
    }
    & > img:nth-child(2) {
      z-index: 10000;
    }
  }

  & > .back-top {
    padding-top: 24px;
  }

  & img {
    display: block;
    cursor: pointer;
  }
}

@media screen and (max-width: 996px) {
  .icon-box {
    display: none;
  }
}
</style>
