var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",class:_vm.isArt ? 'art' : '',style:({ position: _vm.$route.name === 'ArtPaint' ? 'absolute' : 'relative' })},[_c('div',{staticClass:"header-container"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isArt),expression:"!isArt"}],attrs:{"src":require("@/assets/image/jiabaoli-logo.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isArt),expression:"isArt"}],attrs:{"src":require("@/assets/image/jiabaoli-logo-white.png"),"alt":""}})]),_c('ul',{staticClass:"menu",on:{"mouseleave":_vm.handleMenuMouseleave}},_vm._l((_vm.routes),function(item){return _c('li',{key:item.path,class:[
          'menu-item',
          item.path === '/'
            ? _vm.currentRoutePath === item.path
              ? 'active'
              : ''
            : _vm.currentRoutePath.includes(item.path)
            ? 'active'
            : '',
        ],on:{"mouseenter":function($event){return _vm.onPrimaryMouseEnter(item)}}},[(
            ['/building', '/industry', '/international'].includes(item.path)
          )?_c('a',[_vm._v(" "+_vm._s(item.meta.title)+" ")]):_c('Link',{attrs:{"to":_vm.resolvePath(item.path)}},[_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.onPrimaryClick(item.path)}}},[_vm._v(_vm._s(item.meta.title))])]),(_vm.excludePath(item.path))?[(_vm.showSubMenu === item.path)?_c('div',{staticClass:"sub-menu"},[_c('div',{staticClass:"mask",on:{"mouseenter":_vm.hideMenu}}),_c('div',{staticClass:"sub-menu-content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.meta.subTitle))]),(item.path === '/furnish')?[_c('ul',{staticClass:"sub-menu-list"},_vm._l((_vm.subMenuList),function(sub){return _c('li',{key:sub.path,class:[
                        'sub-menu-item',
                        _vm.furnishActive === sub.path ? 'active' : '',
                      ],on:{"mouseover":function($event){return _vm.onSubMenuClick(sub)}}},[_vm._v(" "+_vm._s(sub.meta.title)+" ")])}),0)]:_vm._e()],2),_c('ul',{staticClass:"right",on:{"click":_vm.hideMenu}},[(item.path === '/about')?_vm._l((_vm.subMenuList),function(sub){return _c('li',{key:sub.path,class:[
                      'right-item',
                      'right-about-item',
                      _vm.currentRoutePath.includes(sub.path) ? 'active' : '',
                    ]},[_c('Link',{attrs:{"to":_vm.resolvePath(item.path, sub.path)}},[_c('span',[_vm._v(_vm._s(sub.meta.title))])])],1)}):_vm._e(),(item.path === '/furnish')?[(_vm.furnishActive === 'product')?_vm._l((_vm.productTypeList),function(pro){return _c('li',{key:pro.title,class:[
                        'right-item',
                        'right-product-item',
                        _vm.productActive === pro.index ? 'active' : '',
                      ]},[_c('Link',{attrs:{"to":pro.path}},[_c('span',[_vm._v(_vm._s(pro.title))])])],1)}):_vm._e(),(_vm.furnishActive === 'serve')?[_c('li',{class:[
                        'right-item',
                        'right-serve-item',
                        _vm.currentRoutePath.includes('oldroom') ? 'active' : '',
                      ]},[_c('Link',{attrs:{"to":'/furnish/serve/oldroom'}},[_c('span',[_vm._v("焕新家")])])],1),_c('div',{staticClass:"serve-box"},[_c('li',{class:[
                          'right-item',
                          'right-serve-item',
                          _vm.currentRoutePath.includes('authenticity')
                            ? 'active'
                            : '',
                        ]},[_c('Link',{attrs:{"to":`/furnish/serve/authenticity`}},[_c('span',[_vm._v("真伪查询")])])],1),_c('li',{class:[
                          'right-item',
                          'right-serve-item',
                          _vm.currentRoutePath.includes('certified')
                            ? 'active'
                            : '',
                        ]},[_c('Link',{attrs:{"to":`/furnish/serve/certified`}},[_c('span',[_vm._v("认证查询")])])],1)])]:_vm._e(),(_vm.furnishActive === 'color-inspiration')?[_c('li',{class:[
                        'right-item',
                        'right-color-item',
                        _vm.activeColorInspiration === 1 ? 'active' : '',
                      ]},[_c('Link',{attrs:{"to":'/furnish/color-inspiration/index#postion1'}},[_c('span',[_vm._v("VR色彩空间")])])],1),_c('div',{staticClass:"color-box"},[_c('li',{class:[
                          'right-item',
                          'right-color-item',
                          _vm.activeColorInspiration === 2 ? 'active' : '',
                        ]},[_c('Link',{attrs:{"to":'/furnish/color-inspiration/index#postion2'}},[_c('span',[_vm._v("色彩探索")])])],1),_c('li',{class:[
                          'right-item',
                          'right-color-item',
                          _vm.activeColorInspiration === 3 ? 'active' : '',
                        ]},[_c('Link',{attrs:{"to":'/furnish/color-inspiration/index#postion3'}},[_c('span',[_vm._v("生活灵感")])])],1)])]:_vm._e(),(_vm.furnishActive === 'cooperation')?[_c('li',{class:['right-item', 'right-cooperation-item']},[_c('Link',{attrs:{"to":`/about/zsjm`}},[_c('span',[_vm._v("招商合作")])])],1),_c('li',{class:['right-item', 'right-cooperation-item']},[_c('Link',{attrs:{"to":`/about/introduce`}},[_c('span',[_vm._v("关于嘉宝莉")])])],1)]:_vm._e()]:_vm._e(),(item.path === '/building')?_vm._l((_vm.subMenuList),function(sub){return _c('li',{key:sub.path,class:[
                      'right-item',
                      'right-building-item',
                      _vm.currentRoutePath.includes(sub.path) ? 'active' : '',
                    ]},[_c('Link',{attrs:{"to":_vm.resolvePath(item.path, sub.path)}},[_c('span',[_vm._v(_vm._s(sub.meta.title))])])],1)}):_vm._e(),(item.path === '/industry')?[_c('li',{class:['right-item', 'right-industry-item']},[_c('Link',{attrs:{"to":'https://wwwunit.carpoly.com/furniture'}},[_c('span',[_vm._v("家具涂料")])])],1),_c('div',{staticClass:"industry-box"},[_c('li',{class:[
                        'right-item',
                        'right-industry-item',
                        _vm.currentRoutePath.includes('industrial-coatings')
                          ? 'active'
                          : '',
                      ]},[_c('Link',{attrs:{"to":`/industry/industrial-coatings`}},[_c('span',[_vm._v("工业涂料")])])],1),_c('li',{class:[
                        'right-item',
                        'right-industry-item',
                        _vm.currentRoutePath.includes('ink') ? 'active' : '',
                      ]},[_c('Link',{attrs:{"to":`/industry/ink`}},[_c('span',[_vm._v("油墨")])])],1)])]:_vm._e(),(item.path === '/international')?_vm._l((_vm.subMenuList),function(sub){return _c('li',{key:sub.path,class:[
                      'right-item',
                      'right-international-item',
                      _vm.currentRoutePath.includes(sub.path) ? 'active' : '',
                    ]},[_c('Link',{attrs:{"to":_vm.resolvePath(item.path, sub.path)}},[_c('span',[_vm._v(_vm._s(sub.meta.title))])])],1)}):_vm._e()],2)])]):_vm._e()]:_vm._e()],2)}),0)],1),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isArt),expression:"!isArt"}],staticClass:"icon",attrs:{"src":require("@/assets/image/menu/search-icon.png")},on:{"click":_vm.goSearch}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isArt),expression:"isArt"}],staticClass:"icon",attrs:{"src":require("@/assets/image/menu/search-icon-white.png")},on:{"click":_vm.goSearch}})])
}
var staticRenderFns = []

export { render, staticRenderFns }